import classnames from "classnames";
import React, { Children } from "react";

interface Props {
  label: string
  sublabel?: string
  id?: string
  className?: string
  secondColumnClass?: string
  title?: string
  bigPadding?: boolean
  rowRef?: React.RefObject<HTMLDivElement>
}

const LabelledDivRow = ({ id, className, children, label, secondColumnClass, sublabel, title, bigPadding, rowRef }: React.PropsWithChildren<Props>) => {
  const wrapperClassName = classnames("labelled-div-row flex", {
    [className]: className
  });

  const secondColumnClasses = classnames({
    // s1_cell_bigpadding: bigPadding,
    [secondColumnClass]: secondColumnClass,
    s1_cell_bigpadding: bigPadding
  });

  const filteredChildren = Children.toArray(children).filter(child => child != null && typeof child !== "boolean");

  return <div id={id} className={wrapperClassName} ref={rowRef}>
    <div className="div-row-label" title={title}>
      {label}
      {sublabel && <div className="text-sublabel">{sublabel}</div>}
    </div>
    {Children.map(filteredChildren, child =>
      <div className={secondColumnClasses} style={{ flex: 1, overflowWrap: "anywhere" }}>
        {child}
      </div>
    )}
  </div>;
};

export default LabelledDivRow;