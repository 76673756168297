import { ButtonPrimary, ButtonSecondary } from "components/Atoms/Button";
import Modal from "components/modal";
import React from "react";
import { UseModernOrLegacyHook } from "./hooks";

interface Props {
  modalTitle: string
  useModernOrLegacyHook: UseModernOrLegacyHook
  onLegacySelected: () => void
}

const ChooseModernOrLegacyModal = (props: Props) => {
  const [isSelectingModern, setIsSelectingModern] = React.useState(false);
  const [isSelectingLegacy, setIsSelectingLegacy] = React.useState(false);

  const isSelectingAnything = isSelectingModern || isSelectingLegacy;

  const onEsignSelected = () => {
    setIsSelectingModern(true);
    props.useModernOrLegacyHook.onModernSelected();
  };

  const onLegacySelected = () => {
    setIsSelectingLegacy(true);
    props.onLegacySelected();
  };

  return <Modal
    isOpen
    handleClose={props.useModernOrLegacyHook.onClose}
    title={props.modalTitle}
  >
    <div className="flex-column gap-small">
      <ButtonPrimary
        wide
        onClick={onEsignSelected}
        label={props.useModernOrLegacyHook.modernLabel}
        loading={isSelectingModern}
        iconRight="icon-rocket"
        disabled={isSelectingAnything}
      />
      <ButtonSecondary
        wide
        onClick={onLegacySelected}
        label={props.useModernOrLegacyHook.legacyLabel}
        loading={isSelectingLegacy}
        disabled={isSelectingAnything}
      />
    </div>
  </Modal>;
};

export default ChooseModernOrLegacyModal;