import BookkeepingDropdown from "components/dropdowns/reactselect/BookkeepingDropdown";
import LabelledDivRow from "components/LabelledDivRow";
import { useValidateIsNotNull } from "formvalidation/hooks";
import React from "react";

interface Props {
  value: server.dto.BookkeepingSmall;
  onChange: (bookkeeping: server.dto.BookkeepingSmall) => void;
  options: server.dto.BookkeepingSmall[];
}

const SelectBookkeeping = (props: Props) => {
  const validation = useValidateIsNotNull({ key: "bookkeeping", title: RESX.Bookkeeping.resxBookkeeping, value: props.value });

  const onBookkeepingChange = (bookkeeping: server.dto.BookkeepingSmall) => {
    props.onChange(bookkeeping);
  }

  return <LabelledDivRow label={RESX.Bookkeeping.resxBookkeeping} rowRef={validation.ref}>
    <BookkeepingDropdown value={props.value} onChange={onBookkeepingChange} options={props.options ?? []} config={{ hasError: validation.error.hasError }} />
  </LabelledDivRow>
};

export default SelectBookkeeping;