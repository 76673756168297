import React from "react";
import BaseReactSelectDropdown from "./BaseReactSelectDropdown";
import { type DropdownProps } from "../models";

const BankAccountDropdown = (props: DropdownProps<server.dto.BankAccountSmall>) => {
  return <BaseReactSelectDropdown
    value={props.value}
    onChange={props.onChange}
    options={props.options}
    getLabel={bankAccount => bankAccount.title}
    getSubLabel={bankAccount => bankAccount.beneficiaryName}
    getValue={bankAccount => bankAccount.bankAccountId}
    excludeKeys={props.excludeKeys}
    config={{
      isSearchable: true,
      ...props.config
    }}
  />;
};

export default BankAccountDropdown;