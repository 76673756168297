import classNames from "classnames";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";
import React from "react";

const ButtonGroup = (props: React.PropsWithChildren<any>) => {
  return <div className="button-group-wrapper">
    {props.children}
  </div>;
};

interface ButtonProps {
  active: boolean
  group: string
  className?: string
  onClick: () => void
  type: "primary" | "secondary" | "tertiary";
  otherProps?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
}

const ButtonComponent = (props: React.PropsWithChildren<ButtonProps>) => {
  const buttonClasses = classNames("button-group-button", props.type, props.className, {
    "button-group-button-active": props.active
  });

  return <button
    type="button"
    className={buttonClasses}
    onClick={props.onClick}
    {...HorizontalKeyboardNavigationGroup(props.group)}
    {...props.otherProps}
  >
    {props.children}
  </button>;
};

const Button = {
  Group: ButtonGroup,
  Button: ButtonComponent
};

export default Button;