import React from "react";

interface Options {
  enabled: "legacy" | "modern" | "legacyAndModern"
  modernLabel: string // e.g. "Online invoice", "eSign"
  legacyLabel: string // "Classic workflow"
}

export type UseModernOrLegacyHook = ReturnType<typeof useModernOrLegacy>;

const useModernOrLegacy = (options: Options) => {
  const [screen, setScreen] = React.useState<"chooseLegacyOrModernScreen" | "minimalCreationScreen" | "redirectToLegacy">(undefined);

  const displayModernOrLegacy = () => {
    if(options.enabled === "legacy") {
      setScreen("redirectToLegacy");
    }
    else if (options.enabled === "legacyAndModern") {
      setScreen("chooseLegacyOrModernScreen");
    }
    else {
      setScreen("minimalCreationScreen");
    }

  }

  const onModernSelected = async () => {
    setScreen("minimalCreationScreen");
  };

  return {
    screen,
    onClose: () => setScreen(undefined),
    displayModernOrLegacy,
    onModernSelected,
    modernLabel: options.modernLabel,
    legacyLabel: options.legacyLabel,
    enabled: options.enabled
  }
}

export const useOnlineInvoiceOrLegacy = () => {
  let onlineInvoiceEnabled: "legacy" | "modern" | "legacyAndModern";

  switch (site.currentEnvironment.invoice.enabled) {
    case "onlyLegacy": onlineInvoiceEnabled = "legacy"; break;
    case "onlyOnlineInvoice": onlineInvoiceEnabled = "modern"; break;
    case "legacyAndOnlineInvoice": onlineInvoiceEnabled = "legacyAndModern"; break;
  }

  return useModernOrLegacy({
    enabled: onlineInvoiceEnabled,
    modernLabel: "Online invoice",
    legacyLabel: RESX.ESign.ClassicWorkflow,
  })
}

export const useEsignOrLegacy = () => {
  let esignEnabled: "legacy" | "modern" | "legacyAndModern";

  switch (site.currentEnvironment.eSignEnabled) {
    case "onlyLegacy": esignEnabled = "legacy"; break;
    case "onlyESign": esignEnabled = "modern"; break;
    case "legacyAndESign": esignEnabled = "legacyAndModern"; break;
  }

  return useModernOrLegacy({
    enabled: esignEnabled,
    modernLabel: RESX.ESign.eSign,
    legacyLabel: RESX.ESign.ClassicWorkflow,
  })
}