export const stripNonNumericLeaveSeparator = (str: string, separator: string, allowDecimalSeparator?: boolean): string => {
  const regexString = `[^0-9${separator}]`;

  const regex = new RegExp(regexString, "g");

  let result = str.replace(regex, "");

  // Remove all except the last decimal point
  const allSeparatorRegexp = new RegExp(`[${separator}]`, "g");
  const singleSeparator = new RegExp(`[${separator}]`);
  while (result.match(allSeparatorRegexp)?.length > 1) {
    result = result.replace(singleSeparator, "");
  }

  if (!allowDecimalSeparator) {
    const separatorRegex = new RegExp("\\" + site.culture.numberDecimalSeparator);
    result = result.replace(separatorRegex, "");
  }

  return result;
};

export const toLowerCaseFirstChar = (str: string) => {
  if (str == null) return str;
  return str.substr(0, 1).toLowerCase() + str.substr(1);
};

// Turns an array of strings into a joined string with a cap
// E.g.: ["a", "b", "c", "d"] with a cap of 2 and a separator of ", "
// it will return "a, b (+2)"
export const joinWithMaxLength = (values: string[], separator: string, maxLength: number): string => {
  let result = values.slice(0, maxLength).join(separator);

  if (values.length > maxLength) {
    const remaining = values.length - maxLength;
    result = result += ` (+${remaining})`;
  }

  return result;
};
