import { PhosphorPencilButton } from "components/Atoms/PhosphorIconButton";
import SubLabel from "components/Atoms/Sublabel";
import { RemovableLabel, RemovableProps } from "components/Removables/Removable";
import React from "react";
import { useEditCompanyModal } from "utilities/hookutils";

interface Props extends RemovableProps<server.dto.CompanySmall> {
  hideEditButton?: boolean;
}

const RemovableCompany = (props: Props) => {
  const { openEditCompanyPage } = useEditCompanyModal(props.value?.companyId, props.onChange);

  const canEditCompany = site.permissions.addressBook.canCreate && !props.hideEditButton;

  return <RemovableLabel {...props}>
    <div>
      {props.value.name}
      <SubLabel>{props.value.cityCountry}</SubLabel>
    </div>
    {canEditCompany && <PhosphorPencilButton
      layout="in-square"
      onClick={openEditCompanyPage} />}
  </RemovableLabel>;
};

export default RemovableCompany;