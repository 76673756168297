import XIconRemoveButton from "components/buttons/XIconRemoveButton";
import React from "react";

export interface RemovableProps<T> {
  value: T
  onChange: (value: T) => void
  readonly: boolean
  autoFocus?: boolean
  error: boolean
  innerRef?: React.MutableRefObject<HTMLDivElement | HTMLInputElement>
}

interface Props<T> extends RemovableProps<T> {
  renderRemovableLabel: (props: RemovableProps<T>) => JSX.Element
}

const Removable = <T extends unknown>(props: React.PropsWithChildren<Props<T>>) => {
  const { renderRemovableLabel } = props;
  if (props.value) {
    return renderRemovableLabel(props);
  }

  return <>{props.children}</>;
};

export const RemovableLabel = <T extends unknown>(props: React.PropsWithChildren<RemovableProps<T>>) => {
  return <div ref={props.innerRef} className="flex gap">
    {!props.readonly && <XIconRemoveButton autoFocus={props.autoFocus} onClick={() => { props.onChange(undefined); }} />}
    {props.children}
  </div>;
};

export default Removable;