import React from "react";
import ModalPickerWithChooseButton from "components/Picker/ModalPickerWithChooseButton";
import CompanyList from "./CompanyList";
import { ModalPickerProps } from "../props";
import { useCreateCompanyModal } from "utilities/hookutils";
import RemovableCompany from "components/Removables/RemovableCompany";

interface Props extends ModalPickerProps<server.dto.CompanySmall> {
  modalTitle: string;
  addressType: server.dto.AddressTypes;
  hideEditButton?: boolean;
}

const CompanyModalPicker = (props: Props) => {
  const { openCreateCompanyPage } = useCreateCompanyModal(props.onChange);

  return <ModalPickerWithChooseButton
    canCreateNew={props.canCreateNew}
    onNewClicked={openCreateCompanyPage}
    buttonLabel={RESX.Buttons.btnSelect}
    modalTitle={props.modalTitle}
    modalSize="mini"
    hasError={props.hasError}
    value={props.value}
    mandatory={false}
    renderModalContent={(closeModal) => <CompanyList
      addressType={props.addressType}
      onItemSelected={(company) => {
        closeModal();
        props.onChange(company);
      }}
    />}
    renderRemovable={(innerProps) => <RemovableCompany {...innerProps} hideEditButton={props.hideEditButton} />}
    onChange={props.onChange}
  />;
};

export default CompanyModalPicker;