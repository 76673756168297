import BrandDropdown from "components/dropdowns/reactselect/BrandDropdown";
import LabelledDivRow from "components/LabelledDivRow";
import { useValidateIsNotNull } from "formvalidation/hooks";
import React from "react";

interface Props {
  value: server.dto.BrandSmall;
  onChange: (brand: server.dto.BrandSmall) => void;
  options: server.dto.BrandSmall[];
}

const SelectBrand = (props: Props) => {
  const brandValidation = useValidateIsNotNull({ key: "brand", title: RESX.Branding.resxBranding, value: props.value });

  const onBrandChange = (brand: server.dto.BrandSmall) => {
    props.onChange(brand);
  }

  return <LabelledDivRow label={RESX.Branding.resxBranding} rowRef={brandValidation.ref}>
    <BrandDropdown value={props.value} onChange={onBrandChange} options={props.options ?? []} config={{ hasError: brandValidation.error.hasError }} />
  </LabelledDivRow>
};

export default SelectBrand;