import classNames from "classnames";
import { TwerkingChip } from "components/Atoms/twerkingChip/TwerkingChip";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";
import React from "react";

interface NavigationBarProps {
  selectedMenu: server.dto.MainMenuItem
  currentPage: server.dto.RouteKeys
  newButton?: React.ReactNode
}

const NavigationBar = (props: NavigationBarProps) => {
  const { selectedMenu, currentPage, newButton } = props;
  const subMenuItems = selectedMenu.items.filter(item => !item.hidden);

  return <div className="top-navigation-wrapper">
    <div className="top-navigation-header">
      <div className="top-navigation-title">
        <h1>{selectedMenu.label}</h1>
      </div>
      {newButton && <div>
        {newButton}
      </div>}
    </div>

    {(subMenuItems.length > 0) && <div className="navigation-wrapper">
      <div className="navigation-items">
        {subMenuItems.map(item => {

          const topNavigationItemStyle = classNames({
            "navigation-selected": item.highlight.includes(currentPage)
          });

          if (item.newFeature) {
            return <TwerkingChip offset={2} key={item.url} position="top" id="planningMap" label={RESX.Buttons.btnNew}>
              <a {...HorizontalKeyboardNavigationGroup("topBarNavigation")} href={item.url} className={topNavigationItemStyle}>
                {item.label}
              </a>
            </TwerkingChip>
          }

          return <a {...HorizontalKeyboardNavigationGroup("topBarNavigation")} key={item.url} href={item.url} className={topNavigationItemStyle}>
            {item.label}
          </a>
        }
        )}
      </div>
    </div>}
  </div>;
};

export default NavigationBar;
