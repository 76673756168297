import { type QueryKey, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { type AxiosPromise } from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Pagination } from "../pagination";
import { uuidv4 } from "./uuidv4";
import { useMediaQuery } from "./usehooks";
import { routes } from "./routing/generatedRoutes";

/**
 * This hook runs once after the first render
 *
 * @param func Function that should be run once
 */
export const useComponentDidMount = (func: () => void): void => {
  React.useEffect(func, []);
};

/**
 * This hook stores the value of a prop/state so it can be used in the next render
 *
 * It returns the value that was stored in the previous render.
 * It is similar to componentDidUpdate
 *
 * @param newValue The new value from the current render
 * @returns The value that was stored the previous render
 */
export const usePrevious = <T extends unknown>(newValue: T): T => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = newValue;
  }, [newValue]);
  return ref.current;
};

/**
 * Same as useEffect, except it doesn't run on the first render
 * @param callback
 * @param deps
 */
export const useEffectNotOnMount = (callback: () => void, deps: React.DependencyList) => {
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    callback();
  }, deps);
};

type PaginateFunc = (paginationData: server.dto.PaginationData) => Promise<server.dto.PaginationData>;

export const usePagination = (maxPerPage: number, paginate: PaginateFunc, initialPaginationData?: server.dto.PaginationData) => {
  const [pagination, setPagination] = React.useState<server.dto.PaginationData>({
    hasResults: initialPaginationData?.hasResults ?? false,
    total: initialPaginationData?.total ?? 0,
    page: initialPaginationData?.page ?? 1,
    perPage: maxPerPage,
    maxPerPage,
    pageCount: initialPaginationData?.pageCount ?? 0
  });

  const onPaginate = async (newPage: number) => {
    const newPagination = { ...pagination, page: newPage };
    setPagination(newPagination);

    const newPaginationData = await paginate(newPagination);

    setPagination({
      ...newPaginationData,
      maxPerPage
    });
  };

  // Normally the pagination component makes the initial "paginate" call to the server
  // However sometimes we use pagination inside a panel, but the panel is "loading"
  // When it's loading, the pagination component is not rendered, so the initial "paginate" call is not made
  // So we need to make the initial call from the parent component by using the 'onPaginate'
  // And prevent a second call from being made by the pagination component by passing an initial pagination data
  return {
    page: pagination.page,
    total: pagination.total,
    onPaginate,
    Component: <Pagination
      onPaginate={onPaginate}
      paginationData={pagination}
      noInitialPagination={initialPaginationData != null}
    />
  };
};

type ManualPaginateFunc = (paginationData: server.dto.PaginationData) => void;

// Similar to usePagination
// But it doesn't do an initial API call to the server
// And you have to maintain the pagination state yourself
export const useManualPagination = (initialPage: number, maxPerPage: number, paginate: ManualPaginateFunc) => {
  const [pagination, setPagination] = React.useState<server.dto.PaginationData>({
    hasResults: false,
    total: 0,
    page: initialPage,
    perPage: maxPerPage,
    maxPerPage,
    pageCount: 0
  });

  const onPaginate = (pageNr: number) => {
    // Don't update pagination if the page number is the same
    if (pageNr === pagination.page) {
      return;
    }
    const newPagination: server.dto.PaginationData = { ...pagination, page: pageNr };
    setPagination(newPagination);
    paginate(newPagination);
  };

  return {
    Component: <Pagination
      onPaginate={onPaginate}
      paginationData={pagination}
      noInitialPagination
    />,
    setPagination,
    onPaginate,
    ...pagination
  };
};

// Creates a uniquely named function in the window object
// The function's lifetime is tied to the component's lifetime
// This is used for communication between the parent and the child window, like for the company edit inside a modal
export const useGlobalCallbackFunction = (funcNamePrefix: string, func: (...args) => any, deps: React.DependencyList) => {
  const funcName = React.useRef(`${funcNamePrefix}${uuidv4().replace(/-/g, "")}`);
  React.useEffect(() => {
    window[funcName.current] = func;

    // When the opened page is on another origin, we use `postMessage` to communicate
    // Because the opened page cannot do `window.opener.funcName(result)`
    const postedMessageHandler = (ev: MessageEvent<any>) => {
      if (ev.data.callbackName === (funcName.current + "(result)")) {
        func(ev.data.content);
      }
    };
    window.addEventListener("message", postedMessageHandler);
    return () => {

      delete window[funcName.current];
      window.removeEventListener("message", postedMessageHandler);
    };
  }, deps);

  return funcName.current + "(result)";
};

export const useLegacyModal = <T extends unknown>(generatedFuncName: string, onResult: (result: T) => void) => {
  const funcName = useGlobalCallbackFunction(generatedFuncName, ({ value }: { value: T }) => onResult(value), [onResult]);

  const openModal = (url: string) => {
    SystemOneLibrary
      .exports
      .Utils
      .openPopupModal(url, 1000, 750, funcName);
  };

  return openModal;
}

export const useCreateCompanyModal = (onCompanyCreated: (company: server.dto.CompanySmall) => void, type?: "promoter" | "venue" | "artist") => {
  const qs = type ? `${type}=true` : "";
  const url = `${routes.company_NewModal().absolute}?${qs}`;

  const openModal = useLegacyModal("onCompanyCreate", onCompanyCreated);

  return {
    openCreateCompanyPage: () => openModal(url)
  };
}

export const useEditCompanyModal = (companyId: number, onCompanyEdited: (company: server.dto.CompanySmall) => void) => {
  const url = `${routes.company_EditModal(companyId ?? 0).absolute}?`;
  const openModal = useLegacyModal("onCompanyEdit", onCompanyEdited);

  return {
    openEditCompanyPage: () => openModal(url)
  };
}

interface ApiConfig {
  startEnabled?: boolean
}
export const useApi = <T extends unknown>(apiCall: () => AxiosPromise<T>, queryKey: any[], config?: ApiConfig) => {
  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await apiCall();
      return response.data;
    },
    enabled: config?.startEnabled ?? true,
    retry: false,
    refetchOnWindowFocus: false
  });
};

export function useCustomQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) {
  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    retry: options.retry ?? false,
    refetchOnWindowFocus: options.refetchOnWindowFocus ?? false
  });
}

/**
 * This is a replacement for React Measure
 * @returns ref: The ref to attach to the element you want to measure
 *          height: The height of the element
 */
export const useElementHeight = () => {
  const [height, setHeight] = React.useState<number>();

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return {
    ref: measuredRef,
    height
  };
};

export const useOnClickOutside = <T extends HTMLElement>(
  ref: React.MutableRefObject<T>,
  handler: (event: any) => void
) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

// Viewports from smallest to largest
export const viewports = ["mobile", "desktopSmall", "desktopMedium", "desktopWide", "desktopUltraWide"] as const;

export type ViewportType = typeof viewports[number];

export const useViewport = (): ViewportType => {
  const isMobileLayout = useMediaQuery(`(max-width: ${serverReference.constants.layout.mobileBreakpoint}px)`);
  const isDesktopSmallLayout = useMediaQuery(`(max-width: ${serverReference.constants.layout.desktopSmallBreakpoint}px)`);
  const isDesktopMediumLayout = useMediaQuery(`(max-width: ${serverReference.constants.layout.desktopMediumBreakpoint}px)`);
  const isDesktopWideLayout = useMediaQuery(`(max-width: ${serverReference.constants.layout.desktopWide}px)`);

  if (isMobileLayout) return "mobile";
  if (isDesktopSmallLayout) return "desktopSmall";
  if (isDesktopMediumLayout) return "desktopMedium";
  if (isDesktopWideLayout) return "desktopWide";

  return "desktopUltraWide";
};

interface SemanticInputRefHelper {
  inputRef: React.MutableRefObject<HTMLInputElement>
};
// Semantic UI's ref typings are incorrect, so we use a helper to get the correct ref for the inner input element
export const useSemanticUiInputRef = () => {
  const ref = React.useRef<HTMLInputElement>(null);

  return {
    refSetter: ((input: SemanticInputRefHelper) => {
      ref.current = input?.inputRef?.current;
    }) as any,
    ref
  };
};

export const useIsOverflow = (ref, key: string) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [ref, key]);

  return isOverflow;
};

export const useResize = (ref: React.MutableRefObject<HTMLElement>, onDragEnd: (offset: { x: number, y: number }) => void) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [dragEnd, setDragEnd] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });

  const startDrag = (e) => {
    // Only left mouse button
    if (e.button !== 0) return;

    const boundingRect = ref.current.getBoundingClientRect();
    setStart({
      x: boundingRect.left,
      y: boundingRect.top
    });
    setDragging(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseMove = (e) => {
    if (!dragging) return;
    setOffset({
      x: start.x - e.pageX,
      y: start.y - e.pageY
    });
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseUp = (e) => {
    setDragging(false);
    setDragEnd(true);
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (dragEnd) {
      onDragEnd(offset);
      setOffset({ x: 0, y: 0 });
      setDragEnd(false);
    }
  }, [dragEnd]);

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      document.body.style.cursor = "w-resize";
    } else {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.body.style.cursor = "auto";
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging]);

  return {
    offset,
    resizing: dragging,
    startDrag
  };
};

export type SemanticUiInputRef = ReturnType<typeof useSemanticUiInputRef>;