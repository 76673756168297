import BankAccountDropdown from "components/dropdowns/reactselect/BankAccountDropdown";
import LabelledDivRow from "components/LabelledDivRow";
import { useValidateIsNotNull } from "formvalidation/hooks";
import React from "react";

interface Props {
  value: server.dto.BankAccountSmall;
  onChange: (bankAccount: server.dto.BankAccountSmall) => void;
  options: server.dto.BankAccountSmall[];
}

const SelectBankAccount = (props: Props) => {
  const validation = useValidateIsNotNull({ key: "bankAccount", title: RESX.BankAccount.resxBankAccount, value: props.value, });

  const onBankAccountChange = (bankAccount: server.dto.BankAccountSmall) => {
    props.onChange(bankAccount);
  }

  return <LabelledDivRow label={RESX.BankAccount.resxBankAccount} rowRef={validation.ref}>
    <BankAccountDropdown value={props.value} onChange={onBankAccountChange} options={props.options ?? []} config={{ hasError: validation.error.hasError, placeholder: RESX.BankAccount.resxBankAccount }} />
  </LabelledDivRow>
};

export default SelectBankAccount;