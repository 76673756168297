import moment from "moment";
import classNames from "classnames";
import React, { useState } from "react";
import { PhosphorTrashButton } from "components/Atoms/PhosphorIconButton";

interface NotificationCardProps {
  notification: server.dto.Notification
  for: "list" | "popup"
  onRead: () => void
  onDismiss: () => void
}

const getTitle = (notification: server.dto.Notification) => {
  if (notification.rawTitle) {
    return notification.htmlTitle;
  }

  return notification.actor
    ? `${notification.actor.fullName} ${notification.htmlTitle}`
    : notification.htmlTitle;
};

const NotificationCard = (props: NotificationCardProps) => {
  const { notification, onRead, onDismiss } = props;
  const [isRead, setIsRead] = useState(notification.read);

  const imageUrl = notification.avatarUrl
    ? notification.avatarUrl
    : notification.actor
      ? notification.actor.avatarUrl
      : "https://cdn.systemonesoftware.com/images/systemoneavatar.png";

  return <a
    href={notification.notificationAction.action}
    className={classNames("notification-card-wrapper", {
      "notification-card-wrapper-popup": props.for === "popup"
    })}
    target={notification.notificationAction.openInNewTab ? "_blank" : ""}
    onClick={() => { onRead(); setIsRead(true); }} rel="noreferrer"
  >
    {!isRead && <div className="notification-card-new" onClick={(e) => { e.preventDefault(); }} />}
    <div className="notification-card-content">
      <img src={imageUrl} className="avatar-square" />
      <div className="notification-card-text">
        <div
          className="subtitle-text-medium"
          dangerouslySetInnerHTML={{ __html: getTitle(notification) }}
        />
        <div className="label-text-regular">
          {notification.message}
        </div>
        <div className="notification-card-text-time label-text-regular">
          {moment(notification.createdOn).subtract(30, "seconds").fromNow()}
        </div>
      </div>
    </div>
    <PhosphorTrashButton
      layout="in-square"
      onClick={e => { e.preventDefault(); onDismiss(); }}
    />
  </a>;
};

export default NotificationCard;