import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { onAddNotifications, onNotificationsHidden, onNotificationsRead, onNotificationsUnread, onSetNotificationBadge } from "common/signalR";
import { NoDataAvailableDiv } from "components/NoDataAvailableDiv";
import { Error, Loading } from "components/panels";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useInView } from "react-intersection-observer";
import NotificationCard from "./NotificationCard";
import classNames from "classnames";

interface NotificationListProps {
  onLoad: (page: number, filters: server.dto.ListNotificationFilters) => void
  for: "list" | "popup"
  status?: server.dto.NotificationStatusFilterSettings
}

export interface NotificationListRef {
  markAllAsRead: () => void;
}

const NotificationList = forwardRef<NotificationListRef, NotificationListProps>((props: NotificationListProps, forwardedRef) => {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const [dontShowNotificationIds, setDontShowNotificationIds] = useState<number[]>([]);

  const {
    status,
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["notifications", props.status],
    async ({ pageParam = 1 }) => {
      const res = await window.CoreApi.Notification.Paginated({
        markAllSeen: true,
        channel: "browser",
        notificationStatus: props.status || "noFilter",
        page: pageParam,
        perPage: 25
      });
      props.onLoad(pageParam, res.data.request);
      return res.data;
    },
    {
      getPreviousPageParam: (wrapper) => wrapper.pagination.page - 1,
      getNextPageParam: (wrapper) =>
        wrapper.pagination.page !== wrapper.pagination.pageCount ? wrapper.pagination.page + 1 : undefined
    });

  useImperativeHandle(forwardedRef, () => ({
    markAllAsRead: () => {
      markAllRead.mutate();
    }
  }), [])

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" })
  }, [props.status]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    const { removeEvent } = onSetNotificationBadge(async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent2 } = onAddNotifications(async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent3 } = onNotificationsHidden(async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent4 } = onNotificationsRead(async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent5 } = onNotificationsUnread(async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));

    return () => {
      removeEvent();
      removeEvent2();
      removeEvent3();
      removeEvent4();
      removeEvent5();
    };
  }, []);

  const hideNotification = useMutation({
    mutationFn: async (notification: server.dto.Notification) => window.CoreApi.Notification.Hide([notification.notificationId]),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" })
  });

  const markRead = useMutation({
    mutationFn: async (notification: server.dto.Notification) => window.CoreApi.Notification.MarkRead([notification.notificationId], true),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" })
  });

  const markAllRead = useMutation({
    mutationFn: async () => window.CoreApi.Notification.MarkAllRead("browser"),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" })
  });

  const notifications = data?.pages.map(p => p.data).flat() ?? [];
  const visibleNotifications = notifications
    .sort((a, b) => b.notificationId - a.notificationId)
    .filter(n => !n.hidden && !dontShowNotificationIds.includes(n.notificationId));

  return <>
    {status === "success" && notifications.length === 0 && (<div className={classNames("notification-card-list", {
      "notification-card-list-popup": props.for === "popup",
    })}>
      <NoDataAvailableDiv color="yellow" />
    </div>)}
    {status === "success" && notifications.length > 0 && (<div className={classNames("notification-card-list", {
      "notification-card-list-popup": props.for === "popup",
    })}>
      {visibleNotifications.map((notification, index) =>
        <React.Fragment key={notification.notificationId}>
          {index === visibleNotifications.length - 3 && (<div ref={ref} />)}
          <NotificationCard
            key={notification.notificationId}
            for={props.for}
            notification={notification}
            onRead={() => {
              if (!notification.read) {
                markRead.mutate(notification);
              }
            }}
            onDismiss={() => {
              hideNotification.mutate(notification);
              setDontShowNotificationIds([...dontShowNotificationIds, notification.notificationId]);
            }}
          />
          {(index === visibleNotifications.length - 1 && !hasNextPage) && <div className="notification-no-more-items">
            {RESX.Notifications.NoMoreNotifications}
          </div>}
        </React.Fragment>
      )}
    </div>)}
    {(isLoading || isFetchingNextPage) && (<Loading />)}
    {status === "error" && (<Error />)}
  </>;
});

export default NotificationList;
