import LabelledDivRow from "components/LabelledDivRow";
import CompanyModalPicker from "components/Picker/CompanyPicker/CompanyModalPicker";
import { useValidateIsNotNull } from "formvalidation/hooks";
import React from "react";

interface Props {
  value: server.dto.CompanySmall;
  onChange: (addresseeCompany: server.dto.CompanySmall) => void;
}

const SelectAddresseeCompany = (props: Props) => {
  const validation = useValidateIsNotNull({ key: "addresseeCompany", title: RESX.Document.Addressee, value: props.value });

  const onAddresseeCompanyChange = (addresseeCompany: server.dto.CompanySmall) => {
    props.onChange(addresseeCompany);
  }

  return <LabelledDivRow rowRef={validation.ref} label={RESX.Document.Addressee}>
    <CompanyModalPicker
      modalTitle={RESX.Document.Addressee}
      value={props.value}
      onChange={onAddresseeCompanyChange}
      hideEditButton
      addressType="administrative"
      hasError={validation.error.hasError}
    />
  </LabelledDivRow>
};

export default SelectAddresseeCompany;