import { PhosphorGearAnchor } from "components/Atoms/PhosphorIconAnchor";
import React from "react";
import { routes } from "utilities/routing/generatedRoutes";


const NoBankAccountsWarning = () => {
  return <div className="message ui red flex margin-bottom">
    <div className="grow1 margin-right">
      You must create a bank account for your bookkeeping
    </div>
    <div>
      <PhosphorGearAnchor
        href={routes.bankAccount_List().absolute}
        layout="in-square"
        backgroundColor="light"
        padding="large"
      />
    </div>
  </div>
}

export default NoBankAccountsWarning;