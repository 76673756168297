import React from "react";
import { routes } from "utilities/routing/generatedRoutes";
import SelectInitialValuesModal from "./SelectInitialValuesModal";
import { UseModernOrLegacyHook } from "./hooks";
import ModernOrLegacy from "./ModernOrLegacy";
import { useValidators } from "formvalidation/hooks";
import ValidatorContext from "formvalidation/FormContext";
import SelectBrand from "./SelectBrand";
import SelectBookkeeping from "./SelectBookkeeping";

interface Props {
  bookkeepings: server.dto.BookkeepingSmall[]
  brands: server.dto.BrandSmall[]
  modernOrLegacyHook: UseModernOrLegacyHook;
  qs?: string;
  documentType: Exclude<server.dto.DocumentTypes, "unknown">
}

const CreateEsign = (props: Props) => {
  const hasOnlyOneBrand = props.brands.length === 1;
  const hasOnlyOneBookkeeping = props.bookkeepings.length === 1;

  const skipMinimalCreationScreen = React.useRef(hasOnlyOneBookkeeping && hasOnlyOneBrand);

  const [selectedBrand, setSelectedBrand] = React.useState<server.dto.BrandSmall>(hasOnlyOneBrand ? props.brands[0] : null);
  const [selectedBookkeeping, setSelectedBookkeeping] = React.useState<server.dto.BookkeepingSmall>(hasOnlyOneBookkeeping ? props.bookkeepings[0] : null);

  const onSave = async () => {
    const validationResult = await validators.validateAll();
    if (validationResult.hasErrors) {
      return;
    }

    return window.CoreApi.ESign.Create({
      bookkeepingId: selectedBookkeeping.bookkeepingId,
      brandId: selectedBrand.brandId,
      documentType: props.documentType,
    }).then(r => r.data);
  }

  React.useEffect(() => {
    if (props.modernOrLegacyHook.screen === "minimalCreationScreen" && hasOnlyOneBookkeeping && hasOnlyOneBrand) {
      onSave()
        .then(r => {
          if (!r) return;
          window.location.href = r;
        });
    }
  }, [props.modernOrLegacyHook.screen])

  const redirectToLegacy = () => {
    const qs = props.qs ? `?${props.qs}` : "";
    const documentNewRoute: keyof typeof routes = `${props.documentType}_New`;
    window.location.href = routes[documentNewRoute]().absolute + qs;
  }

  const validators = useValidators();

  return <ValidatorContext settings={validators.validatorSettings}>
    <ModernOrLegacy
      modalTitle={site.currentEnvironment[props.documentType].newLabel}
      useModernOrLegacyHook={props.modernOrLegacyHook}
      redirectToLegacy={redirectToLegacy}
      isLoadingMinimalCreationScreen={skipMinimalCreationScreen.current}
    >
      <SelectInitialValuesModal onSave={onSave} useModernOrLegacyHook={props.modernOrLegacyHook} newLabel={site.currentEnvironment[props.documentType].newLabel}>
        <div className="gray-100 padding-large flex-column gap">
          {!hasOnlyOneBookkeeping && <SelectBookkeeping value={selectedBookkeeping} onChange={setSelectedBookkeeping} options={props.bookkeepings} />}
          {!hasOnlyOneBrand && <SelectBrand value={selectedBrand} onChange={setSelectedBrand} options={props.brands} />}
        </div>
      </SelectInitialValuesModal>
    </ModernOrLegacy>
  </ValidatorContext>
}

export default CreateEsign;