import React from "react";
import ChooseModernOrLegacyModal from "./ChooseModernOrLegacyModal";
import { UseModernOrLegacyHook } from "./hooks";

interface Props {
  useModernOrLegacyHook: UseModernOrLegacyHook
  modalTitle: string
  redirectToLegacy: () => void

  // The minimal creation screen may require some entities (bookkeepings, bank accounts etc) to be fetched from the server
  // Until that's done fetching, we should continue showing the modern or legacy modal before switching to the "minimalCreationScreen"
  isLoadingMinimalCreationScreen: boolean
}

const ModernOrLegacy = (props: React.PropsWithChildren<Props>) => {
  const displayModernOrLegacyWhileLoading = props.useModernOrLegacyHook.enabled !== "modern" && (props.useModernOrLegacyHook.screen === "minimalCreationScreen") && props.isLoadingMinimalCreationScreen;

  if(props.useModernOrLegacyHook.screen === "redirectToLegacy") {
    props.redirectToLegacy();
    return <></>;
  }

  if (props.useModernOrLegacyHook.screen === "chooseLegacyOrModernScreen" || displayModernOrLegacyWhileLoading) {
    return <ChooseModernOrLegacyModal
      modalTitle={props.modalTitle}
      useModernOrLegacyHook={props.useModernOrLegacyHook}
      onLegacySelected={props.redirectToLegacy}
    />;
  }
  else if (props.useModernOrLegacyHook.screen === "minimalCreationScreen" && !props.isLoadingMinimalCreationScreen) {
    return <>{props.children}</>
  }

  return <></>;
};

export default ModernOrLegacy;