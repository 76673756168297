import { type ModalWidth } from "components/modal";
import React from "react";
import { Modal } from "components";
import { type RemovableProps } from "components/Removables/Removable";
import { ButtonText } from "components/Atoms/Button";

interface Props<T> {
  buttonLabel: string
  modalTitle: string
  modalSize: ModalWidth
  hasError: boolean
  mandatory: boolean
  canCreateNew: boolean

  onChange: (newValue: T) => void
  onNewClicked: () => void
  value: T

  renderModalContent: (closeModal: () => void) => JSX.Element
  renderRemovable: (props: RemovableProps<T>) => JSX.Element
}

const ModalPickerWithChooseButton = <T extends unknown>(props: Props<T>) => {
  const { canCreateNew = false } = props;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalJustClosed, setModalJustClosed] = React.useState(false);

  const onChange = (newValue: T) => {
    props.onChange(newValue);
    if (newValue == null && props.mandatory) {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalJustClosed(true);
    setTimeout(() => {
      setModalJustClosed(false);
    }, 2500);
  };

  const hasValue = Boolean(props.value);
  return <>
    {!hasValue && <div className="flex">
      <ButtonText
        navigationGroup="modelPickerButton"
        className="no-padding"
        label={props.buttonLabel}
        onClick={() => { setIsModalOpen(true); }}
        htmlType="button"
        style={{ color: props.hasError ? "var(--error-color)" : undefined, minWidth: 0 }}
      />
      {canCreateNew && <>
        &nbsp;|&nbsp;
        <ButtonText
          navigationGroup="modelPickerButton"
          className="no-padding"
          label={RESX.Buttons.btnNew}
          onClick={props.onNewClicked}
          htmlType="button"
          style={{ color: props.hasError ? "var(--error-color)" : undefined, minWidth: 0 }}
        />
      </>}


    </div>}

    {hasValue && props.renderRemovable({ autoFocus: modalJustClosed, value: props.value, onChange, readonly: false, error: props.hasError })}

    <Modal
      isOpen={isModalOpen}
      handleClose={closeModal}
      width={props.modalSize}
      gap="regular"
      title={props.modalTitle}
      height={500}
    >
      {props.renderModalContent(closeModal)}
    </Modal>
  </>;
};

export default ModalPickerWithChooseButton;