import LabelledDivRow from "components/LabelledDivRow";
import React from "react";
import { Checkbox } from "semantic-ui-react";
import { useCustomQuery } from "utilities/hookutils";
import { routes } from "utilities/routing/generatedRoutes";
import SelectInitialValuesModal from "./SelectInitialValuesModal";
import { UseModernOrLegacyHook } from "./hooks";
import ModernOrLegacy from "./ModernOrLegacy";
import { useValidators } from "formvalidation/hooks";
import ValidatorContext from "formvalidation/FormContext";
import SelectBankAccount from "./SelectBankAccount";
import SelectBrand from "./SelectBrand";
import SelectAddresseeCompany from "./SelectAddresseeCompany";
import NoBankAccountsWarning from "./NoBankAccountsWarning";
import SelectBookkeeping from "./SelectBookkeeping";

interface Props {
  bookkeepings: server.dto.BookkeepingSmall[]
  brands: server.dto.BrandSmall[]
  modernOrLegacyHook: UseModernOrLegacyHook;
  qs?: string;
}

const CreateInvoice = (props: Props) => {
  const hasOnlyOneBrand = props.brands.length === 1;
  const hasOnlyOneBookkeeping = props.bookkeepings.length === 1;

  const shouldStartLoadingData = props.modernOrLegacyHook.screen === "minimalCreationScreen";

  const [selectedBrand, setSelectedBrand] = React.useState<server.dto.BrandSmall>(hasOnlyOneBrand ? props.brands[0] : null);
  const [selectedBookkeeping, setSelectedBookkeeping] = React.useState<server.dto.BookkeepingSmall>(hasOnlyOneBookkeeping ? props.bookkeepings[0] : null);
  const [selectedBankAccount, setSelectedBankAccount] = React.useState<server.dto.BankAccountSmall>();
  const [selectedAddressee, setSelectedAddressee] = React.useState<server.dto.CompanySmall>();
  const [selectedRecipientIds, setSelectedRecipientIds] = React.useState<number[]>([]);

  const [hasFinishedInitializing, setHasFinishedInitializing] = React.useState(false);

  const bankAccountsApi = useCustomQuery({
    queryFn: () => window.CoreApi.BankAccount.ListBankAccountSmall({
      bookkeepings: selectedBookkeeping != null ? [selectedBookkeeping.bookkeepingId] : undefined,
      page: 1,
      perPage: 100
    }).then(r => r.data.data),
    onSuccess: () => setHasFinishedInitializing(true),
    queryKey: ["bankAccounts", { bookkeepingId: selectedBookkeeping?.bookkeepingId }],
    enabled: shouldStartLoadingData,
    initialData: []
  });

  const contactByCompany = useCustomQuery({
    queryFn: async () => window.CoreApi.CompanyToContact.ListContactByCompany({ companyId: selectedAddressee.companyId, isActive: true, page: 1, perPage: 100 }).then(r => r.data.data),
    queryKey: ["contactByCompany", { companyId: selectedAddressee?.companyId }],
    enabled: shouldStartLoadingData && selectedAddressee != null,
    initialData: []
  })

  const hasNoBankAccounts = !bankAccountsApi.isFetching && bankAccountsApi.data.length === 0;

  const onBookkeepingChange = (bookkeeping: server.dto.BookkeepingSmall) => {
    setSelectedBookkeeping(bookkeeping);
    setSelectedBankAccount(null);
  }

  const onAddresseeChange = (company: server.dto.CompanySmall) => {
    setSelectedAddressee(company);
    setSelectedRecipientIds([]);
  }

  const onSave = async () => {
    const validationResult = await validators.validateAll();
    if (validationResult.hasErrors) {
      return;
    }

    return window.CoreApi.OnlineInvoice.Create({
      bookkeepingId: selectedBookkeeping.bookkeepingId,
      brandId: selectedBrand.brandId,
      bankAccountId: selectedBankAccount.bankAccountId,
      addresseeCompanyId: selectedAddressee.companyId,
      recipientContactIds: selectedRecipientIds
    }).then(r => r.data);
  }

  const redirectToLegacy = () => {
    window.location.href = routes.invoice_New().absolute + (props.qs ? `?${props.qs}` : "");
  }

  const validators = useValidators();

  return <ValidatorContext settings={validators.validatorSettings}>
    <ModernOrLegacy
      modalTitle={site.currentEnvironment.invoice.newLabel}
      useModernOrLegacyHook={props.modernOrLegacyHook}
      redirectToLegacy={redirectToLegacy}
      isLoadingMinimalCreationScreen={!hasFinishedInitializing}
    >
      <SelectInitialValuesModal onSave={onSave} useModernOrLegacyHook={props.modernOrLegacyHook} newLabel={site.currentEnvironment.invoice.newLabel}>
        <div className="gray-100 padding-large flex-column gap">
          {hasNoBankAccounts && <NoBankAccountsWarning />}

          {!hasOnlyOneBookkeeping && <SelectBookkeeping value={selectedBookkeeping} onChange={onBookkeepingChange} options={props.bookkeepings} />}

          {!hasOnlyOneBrand && <SelectBrand value={selectedBrand} onChange={setSelectedBrand} options={props.brands} />}

          <SelectBankAccount value={selectedBankAccount} onChange={setSelectedBankAccount} options={bankAccountsApi.data} />

          <SelectAddresseeCompany value={selectedAddressee} onChange={onAddresseeChange} />

          {(selectedAddressee != null && contactByCompany.data.length > 0) && <LabelledDivRow label={RESX.GeneralLabels.Recipients}>
            <div className="flex-column gap-small">
              {contactByCompany.data.map(contactRel => <div key={contactRel.contact.contactId}>
                <Checkbox
                  label={contactRel.contact.name.fullName}
                  checked={selectedRecipientIds.includes(contactRel.contact.contactId)}
                  onChange={(_, value) => setSelectedRecipientIds(prevIds => value ? [...prevIds, contactRel.contact.contactId] : prevIds.filter(x => x !== contactRel.contact.contactId))}
                />
              </div>)}
            </div>
          </LabelledDivRow>}
        </div>
      </SelectInitialValuesModal>
    </ModernOrLegacy>
  </ValidatorContext>
}

export default CreateInvoice;