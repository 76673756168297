import React, { createContext } from "react";

export type ScrollableItems = HTMLDivElement | HTMLInputElement | HTMLTableRowElement;
export type ScrollableRef = React.MutableRefObject<HTMLDivElement> | React.MutableRefObject<HTMLInputElement> | React.MutableRefObject<HTMLTableRowElement>;
export type ValidationType = "automatic" | "manual";

export type FormError = {
  hasError: boolean;
  errorMessage?: string;
}

export interface Validator {
  validate: () => Promise<string | boolean>
  error: FormError
  setError: (error: FormError) => void
  ref: ScrollableRef
  title: string
  type: ValidationType
};

export type ValidatorMap = Record<string, Validator>;

interface ValidationContextParams {
  validators: ValidatorMap
  setValidator: (validator: ValidatorMap) => void
  removeValidator: (key: string) => void

}
export const ValidationContext = createContext<ValidationContextParams>({
  validators: {},
  setValidator: () => undefined,
  removeValidator: () => undefined
});

interface Props {
  settings: ValidationContextParams
}
const ValidatorContext = (props: React.PropsWithChildren<Props>) => {
  return <ValidationContext.Provider value={props.settings}>
    {props.children}
  </ValidationContext.Provider>;
};

export default ValidatorContext;