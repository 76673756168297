import { NotificationIconButton } from "components/Atoms/PhosphorIconButton";
import { ButtonPrimary, ButtonText } from "components/Atoms/Button";
import React, { useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { routes } from "utilities/routing/generatedRoutes";
import NotificationList, { NotificationListRef } from "../../notifications/NotificationList";
import { useNotificationCount } from "./useNotificationCount";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";

interface NotificationMenuProps {
  notificationCount: number
}

const NotificationMenu = (props: NotificationMenuProps) => {
  const [originalTitle] = React.useState(document.title);
  const { badge, onNotificationsListOpen } = useNotificationCount(props.notificationCount);
  const notificationListRef = useRef<NotificationListRef>();

  useEffect(() => {
    if (badge > 0) {
      document.title = `(${badge}) ${originalTitle}`;
    } else {
      document.title = originalTitle;
    }
  }, [badge]);

  return <>
    <Popup
      trigger={<NotificationIconButton
        {...HorizontalKeyboardNavigationGroup("topBar")}
        notificationCount={badge}
        layout="in-circle"
        iconSize="large" />}
      className="top-bar-popup"
      position="bottom right"
      closeOnDocumentClick
      onOpen={() => { }}
      onClose={() => { }}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
    >
      <div aria-modal="true" className="popup-menu-wrapper" style={{ minWidth: 600 }}>
        <div className="popup-menu-header">
          <div className="info-text-medium">{RESX.Notifications.resxNotifications}</div>
          <ButtonText
            label={RESX.NotificationSettings.resxNotificationSettings}
            href={routes.preference_NotificationSettings().absolute}
          />
        </div>
        <NotificationList
          for="popup"
          onLoad={onNotificationsListOpen}
        />
        <div className="popup-menu-footer">
          <ButtonText
            label={RESX.Notifications.SeeAllNotifications}
            href={routes.notification_List().absolute}
          />
          <ButtonPrimary
            label={RESX.Notifications.MarkAllAsRead}
            onClick={() => { notificationListRef.current?.markAllAsRead() }}
          />
        </div>
      </div>
    </Popup>
  </>;
};

export default NotificationMenu;
