import React from "react";
import { Button } from "semantic-ui-react";
import Modal from "components/modal";
import { UseModernOrLegacyHook } from "./hooks";
import * as Sentry from "@sentry/react";

interface Props {
  useModernOrLegacyHook: UseModernOrLegacyHook
  onSave: () => Promise<string | false>
  newLabel: string;
}

const SelectInitialValuesModal = (props: React.PropsWithChildren<Props>) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const onSave = async () => {
    setIsSaving(true);

    const result = await props.onSave();
    if (!result) {
      setIsSaving(false);
      return;
    }
    
    try {
      window.location.href = result;
    } catch (ex) {
      Sentry.captureException(ex);

      setIsSaving(false);
      window.displayGenericModal(RESX.ExceptionMessage.ThereIsAProblem, RESX.GeneralLabels.SomethingUnexpectedHappened, "tiny", "red");
    }
  }

  return <Modal
    isOpen
    handleClose={props.useModernOrLegacyHook.onClose}
    title={props.newLabel}
    width="tiny"
  >
    <div className="flex-column gap-small">
      {props.children}
    </div>

    <div className="flex justify-end">
      <div>
        <Button
          primary
          loading={isSaving}
          disabled={isSaving}
          onClick={onSave}
        >{RESX.Buttons.btnSave}</Button>
      </div>
    </div>
  </Modal>;
};

export default SelectInitialValuesModal;