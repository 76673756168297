import React, { AnchorHTMLAttributes, forwardRef, ReactNode, useState } from "react";
import classNames from "classnames";
import { PhosphorCaretLeft, PhosphorCaretRight, PhosphorCircleHalf, PhosphorDownloadSimple, PhosphorFloppy, PhosphorGear, PhosphorList, PhosphorListBullets, PhosphorMagnifyingGlass, PhosphorPencil, PhosphorQuestion, PhosphorSidebar, PhosphorSquaresFour, PhosphorStar, PhosphorTextIndent, PhosphorTrash, PhosphorX } from "./PhosphorIcon";
import { IconWeight } from "@phosphor-icons/react";

interface PhosphorIconAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string
  layout: "in-circle" | "in-square" | "none"
  backgroundColor?: "transparent" | "light"
  children?: ReactNode
  selected?: boolean
  padding?: "regular" | "large"
  iconSize?: "small" | "regular" | "large"
  iconWeight?: IconWeight;
}

export const PhosphorCaretLeftAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorCaretLeft {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorCaretRightAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorCaretRight {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorCircleHalfAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorCircleHalf {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorDownloadSimpleAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorDownloadSimple {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorFloppyAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorFloppy {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorGearAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorGear {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorListAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorList {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorListBulletsAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorListBullets {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorManifyingGlassAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorMagnifyingGlass {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorPencilAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconAnchor
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorPencil {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconAnchor>
});

export const PhosphorQuestionAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorQuestion {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorSideBarAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconAnchor
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorSidebar {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconAnchor>
});

export const PhosphorSquaresFourAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorSquaresFour {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorStarAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconAnchor
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorStar {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconAnchor>
});

export const PhosphorTextIndentAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorTextIndent {...props} />
  </PhosphorIconAnchor>
);

export const PhosphorTrashAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconAnchor
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorTrash {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconAnchor>
});

const PhosphorIconAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props) => {
  const { iconSize, backgroundColor, ...rest } = props;

  const classes = classNames("phosphor-icon-button", props.className, props.iconSize || "large", {
    "in-circle": props.layout === "in-circle",
    "in-square": props.layout === "in-square",
    "light-bg": props.backgroundColor === "light",
    "selected": props.selected,
    "padding-large": props.padding === "large"
  });

  return <div>
    <a
      {...rest}
      className={classes}
    >
      {props.children}
    </a>
  </div>
});

export const PhosphorXAnchor = forwardRef<HTMLAnchorElement, PhosphorIconAnchorProps>((props, ref) =>
  <PhosphorIconAnchor {...props} ref={ref} >
    <PhosphorX {...props} iconWeight="bold" />
  </PhosphorIconAnchor>
);