import { getNavigationOptionsFromElement } from "./model";

const focusableElements =
  "a:not([disabled]):not([tabindex=\"-1\"]), button:not([disabled]), input[type=text]:not([disabled]), input[type=password]:not([disabled]), textarea:not([disabled]), select:not([disabled]), [tabindex]:not([disabled]):not([tabindex=\"-1\"])";

export const getPreviousElement = (limitedToGroup?: string): HTMLElement | null => {
  const focusableList = document.querySelectorAll(focusableElements);
  const index = Array.from(focusableList).findIndex((element) => document.activeElement === element);
  const prevElement = focusableList[index - 1] as HTMLElement;

  if (!prevElement) {
    return null;
  }

  if (limitedToGroup) {
    const options = getNavigationOptionsFromElement(prevElement);
    if (options.navigationGroup !== limitedToGroup) {
      return null;
    }
  }

  return prevElement;
}

export const getNextElement = (limitedToGroup?: string): HTMLElement | null => {
  const focusableList = document.querySelectorAll(focusableElements);
  const index = Array.from(focusableList).findIndex((element) => document.activeElement === element);
  const nextElement = focusableList[index + 1] as HTMLElement;

  if (!nextElement) {
    return null;
  }

  if (limitedToGroup) {
    const options = getNavigationOptionsFromElement(nextElement);
    if (options.navigationGroup !== limitedToGroup) {
      return null;
    }
  }

  return nextElement;
}

export const getElementToSkipFocusTo = (excludeClassName: string, source: HTMLElement): HTMLElement | null => {
  const focusableList = document.querySelectorAll(focusableElements);
  const sourceIndex = Array.from(focusableList).findIndex((element) => element === source);
  const focusedIndex = Array.from(focusableList).findIndex((element) => document.activeElement === element);
  if (sourceIndex < focusedIndex) {
    return getNextFocusableElement(excludeClassName);
  }

  return getPreviousFocusableElement(excludeClassName);
}

export const getNextFocusableElement = (excludeClassName: string): HTMLElement | null => {
  const focusableList = document.querySelectorAll(focusableElements);
  const index = Array.from(focusableList).findIndex((element) => document.activeElement === element);

  for (let i = index + 1; i < focusableList.length; i++) {
    const nextElement = focusableList[i] as HTMLElement;
    if (!nextElement.classList.contains(excludeClassName)) {
      return nextElement;
    }
  }

  return null;
}

export const getPreviousFocusableElement = (excludeClassName: string): HTMLElement | null => {
  const focusableList = document.querySelectorAll(focusableElements);
  const index = Array.from(focusableList).findIndex((element) => document.activeElement === element);

  for (let i = index - 1; i >= 0; i--) {
    const prevElement = focusableList[i] as HTMLElement;
    if (!prevElement.classList.contains(excludeClassName)) {
      return prevElement;
    }
  }

  return null;
}

export const getNextElementOutsideGroup = (group: string): HTMLElement | null => {
  const focusableList = document.querySelectorAll(focusableElements);
  const index = Array.from(focusableList).findIndex((element) => document.activeElement === element);

  for (let i = index + 1; i < focusableList.length; i++) {
    const nextElement = focusableList[i] as HTMLElement;
    const options = getNavigationOptionsFromElement(nextElement);
    if (options.navigationGroup !== group || options.navigationGroup === "") {
      return nextElement;
    }
  }

  return null;
}

export const getPreviousElementOutsideGroup = (group: string): HTMLElement | null => {
  const focusableList = document.querySelectorAll(focusableElements);
  const index = Array.from(focusableList).findIndex((element) => document.activeElement === element);

  for (let i = index - 1; i >= 0; i--) {
    const prevElement = focusableList[i] as HTMLElement;
    const options = getNavigationOptionsFromElement(prevElement);
    if (options.navigationGroup !== group || options.navigationGroup === "") {
      return getFirstElementOfGroup(prevElement);
    }
  }

  return null;
}

const getFirstElementOfGroup = (element: HTMLElement): HTMLElement => {
  const options = getNavigationOptionsFromElement(element);

  if (options.navigationGroup === "") {
    return element;
  }

  const elementsInGroup = document.querySelectorAll(`[data-navigation-group="${options.navigationGroup}"]`);

  return elementsInGroup[0] as HTMLElement;
}

// Focus shifting on enter always looks for the next "logical" element to focus, in the following order:
//  1. The next element, if it is in the group
//  2. If previous element in the group
//  3. The next element outside the group
export const getNextElementToFocusOnSelect = (group: string): HTMLElement | null => {
  const nextElement = getNextElement(group);
  if (nextElement) {
    return nextElement;
  }

  const previousElement = getPreviousElement(group);
  if (previousElement) {
    return previousElement;
  }

  return getNextElement();
}