import { SelectableListKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";
import React, { useEffect } from "react";

const XIconRemoveButton = ({ onClick, group, autoFocus }: { onClick: () => void, group?: string, autoFocus?: boolean }) => {
  const ref = React.useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, []);

  return <div>
    <a
      ref={ref}
      tabIndex={0}
      {...SelectableListKeyboardNavigationGroup(group)}
      className="icon-x small"
      onClick={onClick}
    />
  </div>;
};

export default XIconRemoveButton;