import { ButtonAccent } from "components/Atoms/Button";
import { useOnlineInvoiceOrLegacy } from "components/EsignOrLegacy/hooks";
import CreateInvoice from "components/EsignOrLegacy/AppCreateInvoice";
import React from "react";

declare const viewModel: server.viewmodel.InvoiceListFrontModel;

const NewInvoice = () => {
  const onlineInvoiceOrLegacy = useOnlineInvoiceOrLegacy();

  return <>
    <ButtonAccent
      label={RESX.GeneralLabels.resxNew}
      onClick={() => onlineInvoiceOrLegacy.displayModernOrLegacy()}
    />

    <CreateInvoice
      modernOrLegacyHook={onlineInvoiceOrLegacy}
      brands={viewModel.brands}
      bookkeepings={viewModel.bookkeepings}
      qs=""
    />
  </>;
};

export default NewInvoice;
