
export const onAdvancingRequest = (callback: (dto: server.dto.AdvancingRequest) => void) => {
    window.signalRConnections.hub.on("AdvancingRequest", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("AdvancingRequest", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("AdvancingRequest")
    }
}

export const onNewAdvancingMessage = (callback: (dto: server.dto.AdvancingMessage) => void) => {
    window.signalRConnections.hub.on("NewAdvancingMessage", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("NewAdvancingMessage", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("NewAdvancingMessage")
    }
}

export const onAdvancingUpload = (callback: (dto: server.dto.AdvancingUpload) => void) => {
    window.signalRConnections.hub.on("AdvancingUpload", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("AdvancingUpload", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("AdvancingUpload")
    }
}

export const onAdvancingDeletion = (callback: (clientFileId: number) => void) => {
    window.signalRConnections.hub.on("AdvancingDeletion", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("AdvancingDeletion", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("AdvancingDeletion")
    }
}

export const onAdvancingResponse = (callback: (dto: server.dto.AdvancingResponse) => void) => {
    window.signalRConnections.hub.on("AdvancingResponse", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("AdvancingResponse", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("AdvancingResponse")
    }
}

export const onAddNotifications = (callback: (dto: server.dto.NotificationForBrowser) => void) => {
    window.signalRConnections.hub.on("AddNotifications", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("AddNotifications", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("AddNotifications")
    }
}

export const onClientActivity = (callback: (dto: server.dto.ClientActivity) => void) => {
    window.signalRConnections.hub.on("ClientActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("ClientActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("ClientActivity")
    }
}

export const onCompanyActivity = (callback: (dto: server.dto.CompanyActivity) => void) => {
    window.signalRConnections.hub.on("CompanyActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("CompanyActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("CompanyActivity")
    }
}

export const onContactActivity = (callback: (dto: server.dto.ContactActivity) => void) => {
    window.signalRConnections.hub.on("ContactActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("ContactActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("ContactActivity")
    }
}

export const onCostCenterActivity = (callback: (dto: server.dto.CostCenterActivity) => void) => {
    window.signalRConnections.hub.on("CostCenterActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("CostCenterActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("CostCenterActivity")
    }
}

export const onESignActivity = (callback: (dto: server.dto.ESignActivity) => void) => {
    window.signalRConnections.hub.on("ESignActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("ESignActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("ESignActivity")
    }
}

export const onInvoiceActivity = (callback: (dto: server.dto.InvoiceActivity) => void) => {
    window.signalRConnections.hub.on("InvoiceActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("InvoiceActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("InvoiceActivity")
    }
}

export const onShowActivity = (callback: (dto: server.dto.ShowActivity) => void) => {
    window.signalRConnections.hub.on("ShowActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("ShowActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("ShowActivity")
    }
}

export const onUserActivity = (callback: (dto: server.dto.UserActivity) => void) => {
    window.signalRConnections.hub.on("UserActivity", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("UserActivity", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("UserActivity")
    }
}

export const onSetNotificationBadge = (callback: (badge: number) => void) => {
    window.signalRConnections.hub.on("SetNotificationBadge", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("SetNotificationBadge", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("SetNotificationBadge")
    }
}

export const onNotificationsRead = (callback: (notificationIds: number[]) => void) => {
    window.signalRConnections.hub.on("NotificationsRead", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("NotificationsRead", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("NotificationsRead")
    }
}

export const onNotificationsUnread = (callback: (notificationIds: number[]) => void) => {
    window.signalRConnections.hub.on("NotificationsUnread", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("NotificationsUnread", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("NotificationsUnread")
    }
}

export const onNotificationsHidden = (callback: (notificationIds: number[]) => void) => {
    window.signalRConnections.hub.on("NotificationsHidden", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("NotificationsHidden", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("NotificationsHidden")
    }
}

export const onSerializationCheck = (callback: (serializationCheckDto: server.dto.SerializationCheck) => void) => {
    window.signalRConnections.hub.on("SerializationCheck", callback);

    return {
        removeEvent: () => window.signalRConnections.hub.off("SerializationCheck", callback),
        removeAllEvents: () => window.signalRConnections.hub.off("SerializationCheck")
    }
}
