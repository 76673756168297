import { ButtonAccent } from "components/Atoms/Button";
import CreateEsign from "components/EsignOrLegacy/AppCreateEsign";
import { useEsignOrLegacy } from "components/EsignOrLegacy/hooks";
import React from "react";

declare const viewModel: server.viewmodel.DocumentListFrontModel;

const NewQuote = () => {
  const esignOrLegacy = useEsignOrLegacy();
  return <>
    <ButtonAccent
      label={RESX.GeneralLabels.resxNew}
      onClick={() => esignOrLegacy.displayModernOrLegacy()}
    />
    <CreateEsign
      modernOrLegacyHook={esignOrLegacy}
      documentType="quote"
      brands={viewModel.brands}
      bookkeepings={viewModel.bookkeepings}
      qs=""
    />
  </>;
};

export default NewQuote;
