import SubLabel from "components/Atoms/Sublabel";
import PickerList from "components/Picker/PickerList";
import React from "react";
import { PickerListProps } from "../props";

interface Props extends PickerListProps<server.dto.CompanySmall> {
  addressType: server.dto.AddressTypes;
}

const perPage = 20;

const CompanyList = (props: Props) => {
  const [instruction, setInstruction] = React.useState<server.dto.ListCompanyInstruction>({
    page: 1,
    perPage,
    keyword: "",
  });

  return <>
    <PickerList
      instruction={instruction}
      onInstructionChange={setInstruction}
      queryKey="companylist"
      api={(instruction) => window.CoreApi.Company.PaginatedSmall(instruction, props.addressType)}
      onItemSelected={props.onItemSelected}

      getError={() => undefined}
      excludeKeys={props.excludeKeys}
      getId={company => company.companyId}
      renderListItem={company => <>
        <div>{company.name}</div>
        <SubLabel>{company.cityCountry}</SubLabel>
      </>}
    />
  </>;
};

export default CompanyList;